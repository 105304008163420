import {
  Box,
  Flex,
  VStack,
  Text,
  Image,
  Button,
  useDisclosure,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import AppLayout from "components/AppLayout";
import BreadCrumb from "components/BreadCrumb";
import React, { useRef, createRef } from "react";
import appColors from "theme/appColors";
import HightlightCard from "components/highlightCardComponent";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import CoursePageModal from "components/courseBundleModal";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { GET_WEBSITE_COURSES_LIST, GET_WEBSTE_COURSE_DETAIL } from "api/urls";
import { getRequestWeb } from "api/utils/getRequestWeb";
import AppTestimonial from "components/AppTestimonial";
import { WEBSITE_COURSES_PAGE, WEBSITE_DASHBOARD } from "navigation/routes";
import AppLoader from "components/AppLoader/AppLoader";
import Linktostore from "components/Linktostore";
import { dangerouslySetHtml } from "components/dangerouslySetHtml";
import useViewMoreLess from "hooks/useViewMoreLess";
import StoreComponent from "components/storeComponent";
import WebButton from "components/WebButton";
import { CourseDetailData } from "./courseData";
import {
  tick,
  pattern,
  overviewicon,
  bundleicon,
  bookmarkicon,
  whatYouLearnIcon,
  wrapTie,
  tag,
} from "assets/index";
import useNavigateToCourseDetail from "hooks/useNavigateToCourseDetail";
import { getPrice } from "utils/helperFunctions";
import useCountry from "hooks/useCountry";

function CourseDetail() {
  const [fakedata] = CourseDetailData;
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [isPayment, setisPayment] = React.useState(false);
  const [hoverCard, setHoverCard] = React.useState(null);
  const elementRef = useRef(fakedata.asideList.map(() => createRef()));
  const { categoryName, courseName } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: viewPlanIsOpen,
    onOpen: viewPlanOnOpen,
    onClose: viewPlanOnClose,
  } = useDisclosure();

  const { state } = useLocation();
  const { isIndia, timeZone } = useCountry();

  const [id, setId] = React.useState("");

  React.useLayoutEffect(() => {
    async function getCourseList() {
      await getRequestWeb(GET_WEBSITE_COURSES_LIST)
        .then(function (data) {
          const CourseList = data.data.data.courses_data;

          const checkCourseName = CourseList.map((each) => {
            const regex = /\s:\s/g;

            if (
              each.identity
                .replace(regex, " ")
                .split(" ")
                .join("-")
                .toLowerCase() === courseName.replace("&amp;", "&")
            ) {
              setId(each.id);
              return each;
            }
            return null;
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    getCourseList();
  }, []);

  const Id = state?.id || id;

  const { data, isLoading } = useQuery(GET_WEBSTE_COURSE_DETAIL(Id), () => {
    if (Id) return getRequestWeb(GET_WEBSTE_COURSE_DETAIL(Id));
  });

  console.log("id", Id);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const navigateTo = useNavigateToCourseDetail();

  const { viewMore: viewMoreOverview, handleViewMore: handleViewMoreOverview } =
    useViewMoreLess();
  const { viewMore: viewMoreGuru, handleViewMore: handleViewMoreGuru } =
    useViewMoreLess();
  const isTab = useBreakpointValue({ base: false, sm: true, lg: false });
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const viewPlanModalSize = useBreakpointValue({ base: "md", md: "lg" });
  const pageData = data?.data?.data;

  function scrollToSection(refElement) {
    refElement.current.scrollIntoView({ behavior: "smooth" });
  }
  const currentCourseImage =
    pageData?.courses_data.image_carousel_thumbnail_image;

  const asideList = [
    {
      icon: overviewicon,
      name: "Overview",
    },
    {
      icon: whatYouLearnIcon,
      name: "What you will learn",
    },
    {
      icon: bookmarkicon,
      name: "Highlights",
    },
    {
      icon: pageData?.guru_data?.image ? pageData?.guru_data.image : "",
      name: "Your Guru",
    },

    {
      icon: bundleicon,
      name: "Value Bundles",
    },
  ];

  // const saveAmt =
  //   // eslint-disable-next-line no-unsafe-optional-chaining
  //   pageData?.bundled_courses_data.actual_price -
  //   // eslint-disable-next-line no-unsafe-optional-chaining
  //   pageData?.bundled_courses_data.discount_price;

  // const courseCount =
  //   pageData?.bundled_courses_data.courses_in_bundle_collection.length;
  // const bundleDescription = `Upto ₹${saveAmt} savings
  // when you buy this course with ${courseCount} more courses.`;

  const bundleDescription = pageData?.bundle_info?.discount_text;

  const videoSize = useBreakpointValue({ base: "330 ", md: "300", lg: "660" });

  const stringReplaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }
    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };

  const pageVideo = {
    "Carnatic Basics - Sarali Varisai": "813777833?h=0200beea8b",
    "Alankaras in 7 Talas": "813778244?h=49c51b15d8",
    "Universal Voice Culture": "813778169?h=949fb0e491",
    "Ear Training Basics": "813777890?h=03ab2e3a28",
    "Carnatic Geethams : Meeting new Ragas & Talas": "813777850?h=4de671cd8a",
    "Nottuswarams by Dikshitar": "813778100?h=fa3e7e1ad4",
    "Hindustani Basics - Alankaar": "813777940?h=ac5af8bb9b",
    "Hindustani Voice Culture": "813777961?h=f2aea23529",
    "Komal & Teevra Swaras": "813778048?h=eb90ed3063",
    "Jantai & Dhattu Varisai": "813778016?h=1900d2de89",
    "Multi Raga Alankaras": "813778077?h=d13408892a",
    "Carnatic Voice Culture": "813777869?h=aa0d86eca3",
    "Swarajathis and Jathiswaram": "813778123?h=5cd90a1ac9",
    "Ear Training Pro : Mastering all the 12 Swarasthanas":
      "814137859?h=ac044ed10b",
    "Introduction to Gamakas": "813777998?h=4fd1dcde1c",
    "Introduction to Akaram": "813777983?h=1592483987",
    "Upper & Lower Sthayi Varisais": "813778152?h=9d2a622978",
    "Raga Kalyani : From Swaras to Manodharma": "814137922?h=defa50fac2",
    "Raga Hamsadhwani : From Swaras to Manodharma": "814137892?h=a427bb9fcb",
    "Raga Mohanam : From Swaras to Manodharma": "814137824?h=05888b29cb",
    "Rhyme to Swara : A fun Ear Training adventure": "814137241?h=57d871c328",
    "Varnams - Raga Mohanam & Pantuvarali": "814137210?h=d0bdfad436",
    "Varnams - Kalyani & Kadanakuthuhalam": "814137185?h=9be45ee506",
    "Varnams - Hamsadhwani & Hindolam": "814137150?h=d9ee9eb36d",
    "Tiny Tots Champ : A journey through Friendly Ragas":
      "814137103?h=4b465a6036",
    "Tiny Tots Bloom : Foundation course for Toddlers":
      "814137055?h=250911071f",
    "Ear Training - Level 1": "855004041?share=copy",
    "Ear Training - Level 2": "855005529?share=copy",
    "Ear Training - Level 3": "855006571?share=copy",

    //new 11-06-2024
    "Ear Training - Level 4": "956345397?badge=0",
    "Ear Training - Level 5": "956346588?badge=0",
    "Ear Training - Level 6": "956345235?badge=0",
    "Meend and Kan Swar": "956367429?badge=0",
    "Ragas for Beginners": "956367314?badge=0",
    "Creating Aalap and Taan": "956366755?badge=0",
    "Krithis in Raga Hamsadhwani & Chakravakam": "956366896?badge=0",
    "Krithis in Raga Vasantha, Amruthavarshini": "956366970?badge=0",
    "Krithis in Raga Arabhi, Mayamalavagoulai": "956367060?badge=0",
    "Developing Breath Control & Resonance": "956367242?badge=0",
    "Introduction to Vocal Registers - Head Voice & Chest Voice": "956367118?badge=0",
    "Developing Flexibility and Agility": "956367191?badge=0",

    //new 19-03-2025
    "Varnams - Mohanam & Pantuvarali": "1067213910?h=2e7bb04358",
    "Nottuswarams - Part 1": "1067213705?h=0a57aaf67f",
    "Nottuswarams - Part 2": "1067213773?h=06a413a9a4",
    "Nottuswarams - Part 3": "1067213854?h=b4056f9d54",
    "Carnatic Geethams - Level 1": "1067213549?h=a88b17bd59",
    "Carnatic Geethams - Level 2": "1067213625?h=e95b459011",
    "Mastering Gamakas - Part 1": "1067213955?h=71b3dbd989",
    "Mastering Gamakas - Part 2": "1067214048?h=01966ec586",
    "Mastering Gamakas - Part 3": "1067214230?h=901424ffbd",
  };

  return (
    <AppLoader isLoading={isLoading || data === undefined}>
      {!isLoading && data && (
        <AppLayout>
          <Box pos="relative">
            <BreadCrumb
              Grandparent="Home"
              GrandparentLink={WEBSITE_DASHBOARD}
              parent="Self Learning"
              parentLink={WEBSITE_COURSES_PAGE}
              currentPage="Course Detail"
            />
            <Flex
              as="header"
              pt={{ base: "5em", md: "7em" }}
              pb={{ base: "2em", md: "5em" }}
              backgroundColor={appColors.brandPrimary["50"]}
              backgroundImage={`url(${pattern})`}
              backgroundRepeat="no-repeat"
              backgroundPosition={{ base: "bottom 0 right 0", md: "right" }}
              direction={{ base: "column", sm: "row" }}
              backgroundSize={{
                base: "75vw",
                sm: "50vw",
                md: "50vw",
                lg: "initial",
              }}
            >
              <VStack
                flexBasis="50%"
                px="2em"
                alignItems="flex-start"
                gap="1em"
              >
                <Text
                  as="h1"
                  variant="headerTwo"
                  w={{ base: "100%", lg: "80%" }}
                >
                  {pageData?.courses_data.identity}
                </Text>
                {/* <Button
                  variant="defaultButton"
                  fontWeight={fontWeights.bold}
                  fontSize={fontSizes.sm}
                  onClick={() => {
                    setisPayment(true);
                    onOpen();
                  }}
                >
                  Enroll now for ₹{pageData?.courses_data.price} / year *
                </Button>{' '} */}
                <WebButton content="Enroll Now" onMobile="Enroll Now" />
                <Box>
                  <Text fontWeight={fontWeights.xl} pb="0.5em">
                    * Save more with a{" "}
                    <span
                      style={{
                        color: appColors.brandSecondary["500"],
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => onOpen()}
                      aria-hidden="true"
                    >
                      discounted bundle!
                    </span>
                  </Text>
                  {!isMobile && !isTab && <Linktostore />}
                </Box>
              </VStack>
              <Box
                flexBasis="50%"
                mt={{ base: "2em", md: "0" }}
                px={{ base: "2em", md: "" }}
              >
                <Box pos="relative">
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  {pageVideo[data?.data?.data?.courses_data?.identity] ? (
                    <div
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <iframe
                        src={`https://player.vimeo.com/video/${
                          pageVideo[data?.data?.data?.courses_data?.identity]
                        }&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "12px",
                        }}
                        title="Carnatic Basics - Sarali Varisai.mp4"
                      ></iframe>
                    </div>
                  ) : (
                    <video
                      width={videoSize}
                      height="400"
                      controls
                      preload="metadata"
                      style={{ borderRadius: "12px" }}
                    >
                      <source
                        src={
                          `${pageData?.courses_data?.web_preview_video}#t=0.001`
                            ? `${pageData?.courses_data?.web_preview_video}#t=0.001`
                            : ""
                        }
                        type="video/mp4"
                      />
                    </video>
                  )}
                  <Image
                    src={tag}
                    alt=""
                    pos="absolute"
                    top="0"
                    left="0"
                    w={{ base: "45px", md: "60px", lg: "initial" }}
                    loading="lazy"
                  />
                </Box>
              </Box>
            </Flex>
            <Flex px="2em">
              <Box
                as="aside"
                flexBasis={{ lg: "20%" }}
                borderRight="1px solid rgba(0, 0, 0, 0.08)"
                display={{ base: "none", lg: "initial" }}
                minW="18vw"
                maxW="18vw"
                mr="2em"
              >
                <Box pos="sticky" top="2em" mt="5em" pb="4em">
                  {asideList.map((each, i) => (
                    <Flex
                      gap="0.5em"
                      borderRight={
                        selectedSection === i
                          ? `3px solid ${appColors.brandPrimary["300"]}`
                          : ""
                      }
                      mb="0.5em"
                      _hover={{
                        color: appColors.brandSecondary["700"],
                      }}
                      color={
                        selectedSection === i
                          ? appColors.brandSecondary["700"]
                          : appColors.brandGrey["400"]
                      }
                      fontWeight={
                        selectedSection === i ? fontWeights.xl : fontWeights.sm
                      }
                      onClick={() => {
                        scrollToSection(elementRef.current[i]);
                        setSelectedSection(i);
                      }}
                      cursor="pointer"
                    >
                      <Image
                        src={each.icon}
                        alt="icon"
                        w="24px"
                        h="24px"
                        borderRadius="50%"
                        loading="lazy"
                      />
                      <Text
                        key={each}
                        fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                      >
                        {each.name}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              </Box>
              <Box
                as="main"
                flexBasis={{ base: "100%", lg: "80%" }}
                pt="5em"
                maxW="75vw"
              >
                <VStack
                  gap="4em"
                  w={{ base: "100%", md: "85%", lg: "65%" }}
                  mx="auto"
                  alignItems="flex-start"
                >
                  <Box ref={elementRef.current[0]} w="100%">
                    {viewMoreOverview ? (
                      <Text
                        display="inline"
                        variant="descriptionText"
                        dangerouslySetInnerHTML={{
                          __html: stringReplaceAll.call(
                            pageData?.courses_data.content,
                            "&nbsp;",
                            " "
                          ),
                        }}
                      />
                    ) : (
                      <Text
                        variant="descriptionText"
                        m="0"
                        p="0"
                        pos="relative"
                      >
                        {stringReplaceAll.call(
                          pageData?.courses_data.description,
                          /\u00A0/g,
                          " "
                        )}
                      </Text>
                    )}
                    <Text
                      onClick={() => handleViewMoreOverview((pre) => !pre)}
                      variant="viewMoreOrLess"
                      cursor="pointer"
                      w="fit-content"
                    >
                      {viewMoreOverview ? "Show Less" : "Read More"}
                    </Text>
                  </Box>
                  <Box
                    border={`1px solid ${appColors.brandPrimary["300"]}`}
                    borderRadius="15px"
                    backgroundColor="white"
                    w="100%"
                    p="1em"
                  >
                    <Flex gap={{ base: "1em", lg: "2em" }}>
                      <Box flexBasis="25%" maxW="86px" h="86px" pos="relative">
                        <Box
                          borderRadius="4px"
                          pos="relative"
                          backgroundColor="white"
                          minH={{ base: "66px", md: "84px" }}
                          maxH={{ base: "68px", md: "96px" }}
                          border={`1px solid ${appColors.brandGrey["200"]}`}
                        >
                          <Box
                            borderRadius="4px"
                            pos="relative"
                            bottom="5px"
                            minH={{ base: "66px", md: "84px" }}
                            maxH={{ base: "68px", md: "96px" }}
                            left="5px"
                            backgroundColor="white"
                            border={`1px solid ${appColors.brandGrey["200"]}`}
                          >
                            <Image
                              src={currentCourseImage}
                              alt=""
                              h="100%"
                              zIndex="10"
                              pos="relative"
                              borderRadius="4px"
                              bottom="5px"
                              left="5px"
                              minH={{ base: "66px", md: "84px" }}
                              maxH={{ base: "68px", md: "96px" }}
                              objectFit="cover"
                              loading="lazy"
                            />
                          </Box>
                        </Box>

                        <Image
                          src={wrapTie}
                          alt=""
                          zIndex="20"
                          pos="absolute"
                          top="-8px"
                          minW="110%"
                          minH={{ base: "66px", md: "84px" }}
                          maxH={{ base: "68px", md: "96px" }}
                          loading="lazy"
                        />
                      </Box>
                      {/* <Image src="" alt="" flexBasis="25%" /> */}
                      <Box flexBasis="75%">
                        <Text
                          fontWeight={fontWeights.xl}
                          fontSize={{ base: fontSizes.sm, lg: "23px" }}
                          color={appColors.brandPrimary["300"]}
                        >
                          Save more with a bundle!
                        </Text>
                        <Text
                          fontWeight={{ base: fontWeights.sm, lg: "600" }}
                          fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
                          color={appColors.brandGrey["700"]}
                          lineHeight={{
                            base: lineHeights.xs,
                            lg: lineHeights.sm,
                          }}
                          mb="1em"
                        >
                          {/* {fakedata.bundle.bundelInfo} */}
                          {bundleDescription}
                        </Text>
                        <Box
                          pos="relative"
                          right={{ base: "5.25em", sm: "0" }}
                          mt={{ base: "0.5em", md: "0" }}
                        >
                          <Button
                            variant="defaultButton"
                            onClick={() => onOpen()}
                            _focus={{ outline: "none" }}
                          >
                            View Details
                          </Button>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box ref={elementRef.current[1]}>
                    <Text
                      size="xl"
                      color={appColors.brandSecondary["700"]}
                      mb="0.5em"
                      as="h2"
                    >
                      What you will learn
                    </Text>
                    {data?.data?.data.courses_data.what_you_will_learn
                      .split(";")
                      .map((each) => (
                        <Flex alignItems="center" gap="0.5em" mt="0.5em">
                          <Image src={tick} alt="tick" loading="lazy" />
                          <Text
                            color={appColors.brandGrey["700"]}
                            fontWeight={fontWeights.sm}
                            fontSize={fontSizes.sm}
                          >
                            {each}
                          </Text>
                        </Flex>
                      ))}
                    <Text
                      variant="viewMoreOrLess"
                      mt="0.75em"
                      cursor="pointer"
                      onClick={viewPlanOnOpen}
                    >
                      View Detailed Plan
                    </Text>
                  </Box>
                  <Box
                    w={{ base: "100%", lg: "initial" }}
                    ref={elementRef.current[2]}
                  >
                    <Text
                      size="xl"
                      color={appColors.brandSecondary["700"]}
                      as="h2"
                    >
                      Course Highlights
                    </Text>
                    <Box
                      display="grid"
                      gridTemplateColumns={{
                        base: "1fr 1fr",
                        md: "1fr 1fr 1fr 1fr",
                        lg: "1fr 1fr",
                      }}
                      gap={{ base: "0.5em", lg: "1em" }}
                      my="1em"
                    >
                      {fakedata.highlights.map((each) => (
                        <HightlightCard
                          image={each.img}
                          title={each.title}
                          description={each.description}
                          key={each.title}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box pb="3em">
                    <Flex
                      alignItems="center"
                      gap="1em"
                      ref={elementRef.current[3]}
                    >
                      <Box>
                        <Image
                          loading="lazy"
                          src={
                            pageData?.guru_data?.image
                              ? pageData?.guru_data.image
                              : ""
                          }
                          alt=""
                          w={{ base: "72px", lg: "120px" }}
                          h={{ base: "72px", lg: "120px" }}
                          borderRadius="50%"
                          objectFit="cover"
                        />
                      </Box>
                      <Box>
                        <Text
                          fontWeight={fontWeights.xl}
                          fontSize={fontSizes.sm}
                          color={appColors.brandPrimary["300"]}
                          as="h2"
                        >
                          About your Guru
                        </Text>
                        <Text size="xl" color={appColors.brandSecondary["700"]}>
                          {pageData?.guru_data?.identity}
                        </Text>
                      </Box>
                    </Flex>
                    <Text
                      fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                      fontWeight={fontWeights.sm}
                      lineHeight={{ base: lineHeights.sm, lg: lineHeights.md }}
                      color={appColors.brandGrey["700"]}
                      mt="1em"
                      pb="0.35em"
                    >
                      {pageData?.guru_data?.description}
                    </Text>
                    {viewMoreGuru && (
                      <Text
                        fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                        fontWeight={fontWeights.sm}
                        lineHeight={{
                          base: lineHeights.sm,
                          lg: lineHeights.md,
                        }}
                        color={appColors.brandGrey["700"]}
                        dangerouslySetInnerHTML={dangerouslySetHtml(
                          pageData?.guru_data.content
                        )}
                      />
                    )}
                    <Text
                      onClick={() => handleViewMoreGuru((pre) => !pre)}
                      variant="viewMoreOrLess"
                      cursor="pointer"
                    >
                      {viewMoreGuru ? "show less" : "Read More"}
                    </Text>
                  </Box>
                </VStack>
              </Box>
            </Flex>
            {pageData?.bundled_courses_data?.courses_in_bundle_collection
              .length >= 1 && (
              <Box bg={appColors.brandPrimary["50"]} px="2em">
                <Box ml={{ lg: "20%" }} py="4em">
                  <Flex
                    w={{ lg: "65%" }}
                    mx="auto"
                    direction={{ base: "column", sm: "row", lg: "column" }}
                    ref={elementRef.current[4]}
                  >
                    <Box flexBasis={{ base: "50%" }}>
                      <Text
                        as="h2"
                        fontWeight={fontWeights.xl}
                        fontSize="39px"
                        color={appColors.brandPrimary["400"]}
                      >
                        Value Bundle
                      </Text>
                      <Text
                        fontWeight={fontWeights.xl}
                        fontSize={fontSizes.xl}
                        color={appColors.brandGrey["900"]}
                      >
                        {pageData?.bundle_name.identity}
                      </Text>
                      <Flex gap="0.5em" alignItems="center">
                        <Text
                          color={appColors.brandGrey["500"]}
                          fontWeight={fontWeights.sm}
                          fontSize={fontSizes.sm}
                          style={{ textDecoration: "line-through" }}
                        >
                          {getPrice(
                            isIndia,
                            pageData?.bundled_courses_data.actual_price,
                            ""
                          )}
                        </Text>
                        <Text
                          fontWeight={fontWeights.xl}
                          fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                          color={appColors.brandGrey["700"]}
                        >
                          {getPrice(
                            isIndia,
                            pageData?.bundled_courses_data.discount_price,
                            " / year"
                          )}
                        </Text>
                      </Flex>
                      {isTab && (
                        <Box>
                          <Text
                            color={appColors.brandGrey["700"]}
                            fontWeight={fontWeights.xl}
                            fontSize={fontSizes.sm}
                          >
                            Get this bundle on the Voxguru App
                          </Text>
                          <StoreComponent onlyApple />
                        </Box>
                      )}
                    </Box>

                    {/* -------- moblie and tab ------ */}

                    {(isTab || isMobile) && (
                      <Flex
                        my="1em"
                        gap="1em"
                        flexBasis={{ base: "50%" }}
                        flexDirection="column"
                        pos="relative"
                        py="2em"
                      >
                        <Text
                          fontWeight={fontWeights.xl}
                          fontSize={fontSizes.xs}
                          color={appColors.brandGrey["900"]}
                          letterSpacing="0.04em"
                          pb="0.5em"
                          pos="absolute"
                          top="30px"
                          left="120px"
                          as="h3"
                        >
                          BUNDLED COURSES
                        </Text>
                        {pageData?.bundled_courses_data.courses_in_bundle_collection.map(
                          (each, i) => (
                            <Flex
                              gap="1em"
                              onClick={() => {
                                navigateTo(
                                  categoryName,
                                  each.course.identity,
                                  each.course.id
                                );
                                scrollTop();
                              }}
                            >
                              <Box
                                w="105px"
                                minW="105px"
                                h="71px"
                                borderRadius="6px"
                              >
                                <Image
                                  src={
                                    each.course.image_carousel_thumbnail_image
                                  }
                                  alt=""
                                  w="100%"
                                  h="100%"
                                  borderRadius="6px"
                                  loading="lazy"
                                />
                              </Box>
                              <Box
                                fontWeight="600"
                                fontSize={{
                                  base: fontSizes.sm,
                                  lg: fontSizes.md,
                                }}
                                color={appColors.brandSecondary["500"]}
                                lineHeight={lineHeights.md}
                                textDecoration="underline"
                                h="fit-content"
                                alignSelf={i === 0 ? "center" : ""}
                                pos="relative"
                                top={i === 0 ? "12px" : ""}
                              >
                                {pageData.courses_data.id === each.course.id ? (
                                  <p>
                                    <span
                                      style={{
                                        color: "black",
                                        paddingRight: "0.3em",
                                        width: "fit-content",
                                      }}
                                    >
                                      [This Course]
                                    </span>
                                    {each.course.identity}
                                  </p>
                                ) : (
                                  <p>{each.course.identity}</p>
                                )}
                              </Box>
                            </Flex>
                          )
                        )}
                      </Flex>
                    )}

                    {/* ---------- desktop ------------- */}

                    {!isTab && !isMobile && (
                      <Flex my="1em" gap="1em" flexBasis={{ base: "50%" }}>
                        <Box
                          minW={{ lg: "235px" }}
                          h={{ lg: "180px" }}
                          flexBasis={{ base: "35%" }}
                          pos="relative"
                        >
                          {[
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            ...data?.data?.data.bundled_courses_data
                              .courses_in_bundle_collection,
                          ]
                            .reverse()
                            .map((each, i) => (
                              <Box
                                pos="absolute"
                                w={{ base: "105px", lg: "204px" }}
                                h={{ base: "70px", lg: "136px" }}
                                borderRadius="6px"
                                bottom={`${i * 10}px`}
                                left={`${i * 10}px`}
                                zIndex={`${i * 10}`}
                                border={
                                  i ===
                                  // eslint-disable-next-line no-unsafe-optional-chaining
                                  data?.data?.data.bundled_courses_data
                                    .courses_in_bundle_collection.length -
                                    1
                                    ? `2px solid ${appColors.brandPrimary["300"]}`
                                    : "1px solid #000"
                                }
                              >
                                <Image
                                  src={
                                    each.course.image_carousel_thumbnail_image
                                  }
                                  alt=""
                                  w="100%"
                                  h="100%"
                                  borderRadius="6px"
                                  objectFit="cover"
                                  loading="lazy"
                                />
                              </Box>
                            ))}
                        </Box>
                        <Box flexBasis={{ lg: "65%" }} pl={{ lg: "2em" }}>
                          <Text
                            fontWeight={fontWeights.xl}
                            fontSize={fontSizes.xs}
                            color={appColors.brandGrey["900"]}
                            letterSpacing="0.04em"
                            pb="0.5em"
                            as="h3"
                            // className={hoverCard ? 'pointerMove' : 'pointer'}
                          >
                            BUNDLED COURSES
                          </Text>
                          {data?.data?.data.bundled_courses_data.courses_in_bundle_collection.map(
                            (eachCourse) => (
                              <Box
                                cursor="pointer"
                                fontWeight="600"
                                fontSize={{
                                  base: fontSizes.sm,
                                  lg: fontSizes.md,
                                }}
                                color={appColors.brandSecondary["500"]}
                                lineHeight={lineHeights.md}
                                textDecoration="underline"
                                mt="0.45em"
                                className={
                                  eachCourse.course.id === hoverCard
                                    ? "pointer"
                                    : ""
                                }
                                onClick={() => {
                                  navigateTo(
                                    categoryName,
                                    eachCourse.course.identity,
                                    eachCourse.course.id
                                  );
                                  scrollTop();
                                }}
                                onMouseEnter={() =>
                                  setHoverCard(eachCourse.course.id)
                                }
                                onMouseLeave={() => setHoverCard(null)}
                              >
                                {pageData.courses_data.id ===
                                eachCourse.course.id ? (
                                  <p>
                                    <span
                                      style={{
                                        color: "black",
                                        paddingRight: "0.3em",
                                      }}
                                    >
                                      [This Course]
                                    </span>
                                    {eachCourse.course.identity}
                                  </p>
                                ) : (
                                  <p>{eachCourse.course.identity}</p>
                                )}
                              </Box>
                            )
                          )}
                        </Box>
                      </Flex>
                    )}

                    {isMobile && (
                      <Box>
                        <Text
                          color={appColors.brandGrey["700"]}
                          fontWeight={fontWeights.xl}
                          fontSize={fontSizes.sm}
                          textAlign="center"
                        >
                          Get this bundle on the Voxguru App
                        </Text>
                        <StoreComponent />
                      </Box>
                    )}
                    {!isTab && !isMobile && (
                      <Box>
                        <Button
                          variant="defaultButton"
                          fontWeight={fontWeights.bold}
                          fontSize={fontSizes.sm}
                          my="1em"
                          onClick={() => onOpen()}
                          _focus={{ outline: "none" }}
                        >
                          View Details
                        </Button>
                        <Linktostore orangeColor />
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Box>
            )}
            <AppTestimonial testimonialData={data} />
          </Box>
          <CoursePageModal
            isOpen={isOpen}
            onClose={onClose}
            isPayment={isPayment}
            setisPayment={setisPayment}
            bundleDetails={pageData}
            categoryName={categoryName}
            bundleDescription={bundleDescription}
            currentCourseImage={currentCourseImage}
            courseprice={pageData?.courses_data.price}
            isMobile={isMobile}
          />
        </AppLayout>
      )}
      <Modal
        isOpen={viewPlanIsOpen}
        onClose={viewPlanOnClose}
        isLazy
        size={viewPlanModalSize}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            mx="auto"
            fontWeight={fontWeights.xl}
            fontSize={fontSizes.md}
            color={appColors.brandGrey["700"]}
          >
            Course Plan
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          {pageData && (
            <ModalBody>
              {pageData?.view_course_plan_data.map((each) => (
                <Box pb="1.5em">
                  <Text
                    fontWeight={fontWeights.xl}
                    fontSize={fontSizes.md}
                    color={appColors.brandSecondary["500"]}
                  >
                    {each.identity}
                  </Text>
                  <Text
                    fontWeight={fontWeights.xl}
                    fontSize={fontSizes.sm}
                    color={appColors.brandGrey["500"]}
                    py="0.3em"
                  >
                    {each.description}
                  </Text>
                  {each.what_you_will_learn.split(";").map((item) => (
                    <Flex alignItems="center" gap="0.5em" mt="0.5em">
                      <Image src={tick} alt="tick" loading="lazy" />
                      <Text
                        color={appColors.brandGrey["700"]}
                        fontWeight={fontWeights.sm}
                        fontSize={fontSizes.sm}
                      >
                        {item}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              ))}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </AppLoader>
  );
}

export default CourseDetail;
